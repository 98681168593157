<template>
  <div class="d-flex justify-content-center">
    <h3>Redirecting to IMS...</h3>
  </div>
</template>

<script>
import axios from 'axios'
import {
  failedToast, hideLoader, showLoader, successToast,
} from '@/utils/common'
import constants from '@/constants'
import { getCompanyLoggedInUser } from '@/auth/utils'

export default {
  name: 'RedirectAutoLogin',
  data() {
    return {
      code: this.$route.params.code,
      timestamp: this.$route.params.timestamp,
    }
  },
  mounted() {
    this.autoLogin()
  },
  methods: {
    async autoLogin() {
      showLoader()
      axios.get(`${constants.COMPANY_API_PREFIX}/auto-login/${this.code}/${this.timestamp}`).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.status == constants.SUCCESS) {
          localStorage.setItem('accessToken', response.data.data.user_details.token.plainTextToken)
          localStorage.setItem('menu', JSON.stringify(response.data.data.menu))
          localStorage.setItem('userData', JSON.stringify(response.data.data.user_details))
          localStorage.setItem('permission', response.data.data.permission)
          this.userData = response.data.data.user_details
          this.menu = response.data.data.menu
          this.permission = response.data.data.permission
          this.$router.replace(getCompanyLoggedInUser(this.userData.user_type))
            .then(() => {
              successToast(`Welcome ${this.userData.firstname || this.userData.username}`)
            })
          hideLoader()
        } else {
          failedToast('Something Went Wrong!')
          hideLoader()
          this.$router.push('/link-expired')
        }
      }).catch(() => {
        failedToast('Something Went Wrong!')
        hideLoader()
        this.$router.push('/link-expired')
      })
    },
  },
}
</script>

<style>

</style>
